import axios from 'axios';
import { API_BASE } from '../utils/constants';
import AxiosInterceptor from "../utils/AxiosInterceptor";
const API_URL = API_BASE + "admin/";

const apiAuthLogin = {
  profileFeature: (value) =>  AxiosInterceptor().post(API_BASE + "getAllContent", value),
  addProfileFeature: (value) => AxiosInterceptor().post(API_BASE + "addContent", value),
  updateProfileFeature: (value) => AxiosInterceptor().post(API_BASE + "updateContent", value),
  deleteProfileFeature: (id) => AxiosInterceptor().get(API_BASE + `deleteContent/${id}`),
  statusProfileFeature: (value) => AxiosInterceptor().post(API_BASE + "changeStatus", value),
};

export default apiAuthLogin;
