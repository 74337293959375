import AxiosInterceptor from "../utils/AxiosInterceptor";

const QuestionService = {
    getAll: (value) => AxiosInterceptor().post("/getAllQuestion", value),
    edit: (value) => AxiosInterceptor().post("/updateQuestion", value),
    getById: (value) => AxiosInterceptor().get(`/getOneQuestion/${value?.id}`),
    add: (value) => AxiosInterceptor().post("/addQuestion", value),
    delete: (value) => AxiosInterceptor().get(`/deleteQuestion/${value}`),
};

export default QuestionService;