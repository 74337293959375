import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import {
  Grid,
  Card,
  Typography,
  CardHeader,
  CardContent,
  Stack,
  Button,
  Box,
  Divider,
  Avatar,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";
import SubscriptionServiceApi from "../../services/subscriptionService";

const mockData = [
  { _id: "1", type: "Weekly Plan", amount: "10" },
  { _id: "2", type: "Monthly Plan", amount: "15" },
];

const SubscriptionView = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const navigate = useNavigate();
  console.log("id", id);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await SubscriptionServiceApi.getOneSubscription({
          id: id,
        });
        setData(response?.data?.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <Box>
      <Helmet>
        <title>Sbscription Plan</title>
      </Helmet>
      <CardLayout>
        <CardHeader title="Plan View" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ boxShadow: 4, borderRadius: 3, overflow: "hidden" }}>
                <CardContent>
                  <Stack spacing={2}>
                    <Typography variant="h6">Subscription Type</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.subscriptionType || ""}
                    </Typography>
                    <Divider></Divider>

                    <Typography variant="h6">Price</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.price || ""}
                    </Typography>
                    <Divider></Divider>

                    <Typography variant="h6">Subscription Features</Typography>
                    {Array.isArray(data?.features) ? (
                      <List>
                        {data.features.map((feature, index) => (
                          <ListItem key={index} disableGutters>
                            <ListItemText primary={`• ${feature}`} />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Typography
                        variant="body1"
                        sx={{ overflowWrap: "break-word" }}
                      >
                        {data?.features || "No features available"}
                      </Typography>
                    )}
                    <Divider></Divider>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </CardLayout>
    </Box>
  );
};

export default SubscriptionView;
