//-----------------------Shivangi mam--------------------
// export const API_BASE = "http://172.16.11.135:3002/admin/";
// export const FILE_URL = 'http://172.16.11.135:3002/';
// export const IMAGE_BASE = "http://172.16.11.135:17151/";

//--------------------------karan---------------------------------------
// export const API_BASE = "http://172.16.11.135:3002/admin/";
// export const FILE_URL = 'http://172.16.11.135:3002/';
// export const IMAGE_BASE = "http://172.16.11.135:3002/";

export const API_BASE = "https://oopsapi.devtechnosys.tech/admin/";
export const FILE_URL = 'https://oopsapi.devtechnosys.tech/';
export const IMAGE_BASE = "https://oopsapi.devtechnosys.tech/";

export const BASE_API = API_BASE + "/api";
export const MAIN_URL = BASE_API + "/api/admin";

export const MOBILE_REGEX = /^[0-9]{10}$/;

export const PASSWORDS_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%^&*?])[A-Za-z\d#$@!%^&*?]{8,}$/;

  export const EMAIL_REGEX =
  /^[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;
export const NOSPACE_REGEX = /^(?!\s+$).*/;

export const NOSPACE = "Spaces are not allowed";

export const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "video/mp4",
  "video/x-ms-wmv",
  "video/quicktime",
  "video/webm",
  "application/pdf",
  "text/plain",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

export const MULTIPLESPACE = /  +/g;

export const CARD_REGEX =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/g;

export const NODIGITREGEX = /^([^0-9]*)$/;

export const DECIMALPATTERN = /^[0-9]+(\.[0-9]{1,2})?$/;

export const REMOVEHTML = /(<([^>]+)>)/gi;

export const GENDER_OPTIONS = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Prefer not to say", label: "Prefer not to say" },
];

export const STATUS_OPTIONS = [
  { value: false, label: "Active" },
  { value: true, label: "Inactive" },
];
export const Verification_OPTIONS = [
  { value: "pending", label: "Pending" },
  { value: "cancelled", label: "Cancelled" },
];
export const PLAN_OPTIONS = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];


export const employeeRegisterTabs = {
  tab1: "client-information",
  tab2: "household-service-plan",
  tab3: "barrier-removal-plan",
  tab4: "abc-progress-client-note",
  tab5: "abc-program-contact-note",
}

export const Regex = {
  EMAIL:
    /^(?=[a-zA-Z0-9._]*[a-zA-Z])(?=[a-zA-Z0-9._])[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
  USERNAME:
    /^[a-zA-Z0-9](_(?!(\. |_))|\.(?!( _|\.)) |[a-zA-Z0-9]){3,64}[a-zA-Z0-9]$/,
  PASSWORD: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
  POSTALCODE: /^\d{6}$/g,
  MBNO: /^\+?\d{1,4}?\d{10}$/g,
};

export const TokenName = "time-clock-token"
