import {
  Grid,
  CardHeader,
  CardContent,
  Box,
  TextField,
  CircularProgress,
  Avatar,
  Button,
} from "@mui/material";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import errorHandler from "../../utils/errorHandler";
import { IMAGE_BASE } from "../../utils/constants";
import LoadingButton from "@mui/lab/LoadingButton";
import CardLayout from "../../layouts/CardLayout";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { category } from "../../utils/Schema";
import GiftServiceApi from "../../services/GiftService";

const mockData = [
    { _id: "1", name: "Gift One", coin: "10" },
    { _id: "2", name: "Gift Two", coin: "15" },
  ];

function AddVirtualGift() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);
  const [data, setData] = useState();

  const { id } = useParams();
  const fetchTaskData = useCallback(async () => {
    if (id) {
      setLoading(true);
        try {
          const response = await GiftServiceApi.getOneGift({ id });
          const resData = response?.data?.data;
          setImagePreview(IMAGE_BASE + resData?.giftImage);

          setData(resData);
        } catch (error) {
          errorHandler(error);
        } finally {
          setLoading(false);
        }
    }
  }, [id]);

  useEffect(() => {
    
    fetchTaskData();
  }, [fetchTaskData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.title || "",
      coins: data?.coins || "",
      imagePath: data?.imagePath || "gifts",
      Giftimage: data?.giftImage || "",
    },
    validationSchema: category,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        if (id) {
          formData.append("id", id);
        }
        formData.append("title", values?.name);
        formData.append("coins", values?.coins);
        formData.append('imagePath', "gifts")
        if (selectedImage) formData.append("giftImage", selectedImage);

        const response = id
          ? await GiftServiceApi.updateGift(formData)
          : await GiftServiceApi.addGift(formData, values);

        toast.success(response?.data?.message, { toastId: "success" });
        navigate(-1);

        fileInputRef.current.value = "";
        setSelectedImage(null);
        setImagePreview(null);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];
    const validTypes = ["image/jpeg", "image/png", "image/gif"];
  
    if (selectedImage && selectedImage.name === file?.name) {
      toast.error("You cannot upload the same image again.");
      fileInputRef.current.value = "";
      return;
    }
  
    if (file && validTypes.includes(file.type)) {
      setImageLoading(true);
      setSelectedImage(file);
      setImagePreview(URL.createObjectURL(file));
      formik.setFieldValue("Giftimage", file); // Update formik value
      setTimeout(() => {
        setImageLoading(false);
      }, 2000);
    } else {
      toast.error("Please upload a valid image (JPEG, PNG, or GIF).");
      fileInputRef.current.value = "";
    }
  };


  return (
    <Box>
      <Helmet>
        <title>{id ? "Edit" : "Add"} Gift</title>
      </Helmet>
      <CardLayout>
        <CardHeader title={`${id ? "Edit" : "Add"} Gift`} />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                {/* Image Section */}
                <Grid item xs={12} md={4}>
                  {/* Hidden File Input */}
                  <input
                    accept="image/*"
                    type="file"
                    ref={fileInputRef}
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />

                  {/* Image Preview and Clickable Upload Box */}
                  <Box
                    mt={2}
                    onClick={() => fileInputRef.current.click()}
                    className="upload-box"
             
                  >
                    {imageLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Avatar
                        src={imagePreview || "/path/to/default-avatar.jpg"}
                        alt="preview"
                        className="avatar"
                      />
                    )}
                  </Box>
                  {formik.touched.Giftimage && formik.errors.Giftimage ? (
                    <div className="error-msg">{formik.errors.Giftimage}</div>
                  ) : null}

                  {/* Centered Upload Button */}
                  <Box mt={2} display="flex" justifyContent="center">
                    <Button
                      size="large"
                      type="button"
                      variant="contained"
                      onClick={() => fileInputRef.current.click()}
                    >
                      {id ? "Edit" : "Upload"} Image
                    </Button>
                  </Box>
                 
                </Grid>

                {/* Form Fields Section */}
                <Grid item xs={6} md={4}>
                  <TextField
                    fullWidth
                    label="Name"
                    required
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    name="name"
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />

                  <Box mt={2}>
                    <TextField
                      fullWidth
                      label="Coins"
                      required
                      value={formik.values.coins}
                      onChange={formik.handleChange}
                      name="coins"
                      error={Boolean(
                        formik.touched.coins && formik.errors.coins
                      )}
                      helperText={formik.touched.coins && formik.errors.coins}
                    />
                  </Box>

                  <Box mt={3}>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={loading}
                    >
                      {id ? "Edit" : "Add"} Gift
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </CardContent>
      </CardLayout>
    </Box>
  );
}

export default AddVirtualGift;
