import AxiosInterceptor from "../utils/AxiosInterceptor";

const apiUsers = {
    changePassword: (values) => AxiosInterceptor().post(`/changePassword`, values),
    getSetting:(params) =>AxiosInterceptor().get(`access/getSetting`, {params}),
    updateSetting:(values) =>AxiosInterceptor().post(`access/updateSetting`, values),
    // getAdminDetails:(values)=>AxiosInterceptor().post(`access/getAdminDetails`, values),
};

export default apiUsers;
