import { Box, CardContent, CardHeader, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import FieldErrorMessage from "../../components/FieldErrorMessage";
import SaveButton from "../../components/SaveButton";
import CardLayout from "../../layouts/CardLayout";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import EmailTemplateList from "../../services/emailTemplateLits";
import { EmailTemplateSchema } from "../../utils/Schema";
import EditorJodit from "../../components/editor/editor";

function EditAddEmail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      slug: "",
      title: "",
      subject: "",
      status: 1,
      content: "",
    },
    validationSchema: EmailTemplateSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const res = id
          ? await EmailTemplateList.edit(values)
          : await EmailTemplateList.add(values);

        toast.success(res?.data?.message? res?.data?.message : "Email template Updated successfully.");
        navigate("/email-template-management");
      } catch (error) {
        errorHandler(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue, resetForm } = formik;

  useEffect(() => {
    if (id) {
      const getInstitutionById = async () => {
        try {
          const response = await EmailTemplateList.getById({ id });
          const resData = response?.data?.data;
          resetForm({
            values: {
              id: resData?._id,
              title: resData?.title || "",
              subject: resData?.subject || "",
              status: resData?.status,
              slug: resData?.slug || "",
              content: resData?.content || "",
            },
          });
        } catch (error) {
          errorHandler(error);
        }
      };

      getInstitutionById();
    }
  }, [id, resetForm]);

  return (
    <Box>
      <Helmet>
        <title>{id ? "Edit" : "Add"} Email Template</title>
      </Helmet>
      <CardLayout>
        <CardHeader title={`${id ? "Edit" : "Add"} Email Template`} />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Title"
                    name="title"
                    error={Boolean(touched?.title && errors?.title)}
                    {...getFieldProps("title")}
                  />
                  <FieldErrorMessage formik={formik} name={"title"} />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Subject"
                    required
                    error={Boolean(touched?.subject && errors?.subject)}
                    {...getFieldProps("subject")}
                  />
                  <FieldErrorMessage formik={formik} name="subject" />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Slug"
                    required
                    error={Boolean(touched?.slug && errors?.slug)}
                    {...getFieldProps("slug")}
                  />
                  <FieldErrorMessage formik={formik} name="slug" />
                </Grid>

                <Grid item xs={12}>
                  <label>Description</label>
                  <EditorJodit
                    formik={formik}
                    fieldName="description"
                    onChange={(val) => formik.setFieldValue("content", val)}
                    data={formik.values.content}
                  />
                  {formik.touched.content && formik.errors.content && (
                    <FormHelperText error>
                      {formik.errors.content}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    {console.log(values?.status)}
                    <InputLabel
                      id="status-select-label"
                      error={Boolean(touched?.status && errors?.status)}
                    >
                      Status
                    </InputLabel>
                    <Select
                      labelId="status-select-label"
                      id="status-select"
                      value={values?.status}
                      label="Status"
                      onChange={(e) => setFieldValue("status", e?.target?.value)}
                      error={Boolean(touched?.status && errors?.status)}
                    >
                      <MenuItem value={1}>Active</MenuItem>
                      <MenuItem value={0}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                  <FieldErrorMessage formik={formik} name={"status"} />
                </Grid>
              </Grid>
              <SaveButton loading={formik.isSubmitting} id={id} />
            </Form>
          </FormikProvider>
        </CardContent>
      </CardLayout>
    </Box>
  );
}

export default EditAddEmail;