import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Page from "../../components/Page";
import ProfileManager from "./ProfileManager";
import apiAuthLogin from "../../services/authServiceLogin";

const sections = [
  { slug: "eye-color", title: "Eye Color" },
  { slug: "hair-color", title: "Hair Color" },
  { slug: "beard-type", title: "Beard Type" },
  { slug: "skin-color", title: "Skin Color" },
  { slug: "body-type", title: "Body Type" },
  { slug: "personality", title: "Personality" },
  { slug: "love-language", title: "Love Language" },
  { slug: "things-like", title: "Things I Like" },
];

const UserProfile = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true)
  const [paginationState, setPaginationState] = useState(
    sections.reduce((acc, section) => {
      acc[section.slug] = { pageNumber: 1, pageSize: 5, totalItems: 0 };
      return acc;
    }, {})
  );

  const fetchProfileData = async (slug, pageNumber = 1, pageSize = 5) => {
    try {
      setLoading(true)
      const value = { search: "", pageSize, pageNumber, slug };
      const response = await apiAuthLogin.profileFeature(value);
      const result = response?.data?.data;

      setData((prevData) => ({
        ...prevData,
        [slug]: {
          items: result?.result || [],
          totalItems: result?.totalRecords || 0,
        },
      }));

      setPaginationState((prev) => ({
        ...prev,
        [slug]: {
          ...prev[slug],
          totalItems: result?.totalRecords || 0,
        },
      }));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false)
  }
  };

  const useFetchProfileData = (slug) => {
    useEffect(() => {
      const { pageNumber, pageSize } = paginationState[slug];
      fetchProfileData(slug, pageNumber, pageSize);
    }, [slug, paginationState[slug].pageNumber, paginationState[slug].pageSize]);
  };

  // Call the custom hook for each slug explicitly
  useFetchProfileData("beard-type");
  useFetchProfileData("eye-color");
  useFetchProfileData("hair-color");
  useFetchProfileData("skin-color");
  useFetchProfileData("body-type");
  useFetchProfileData("personality");
  useFetchProfileData("love-language");
  useFetchProfileData("things-like");

  const handlePageChange = (slug, newPage) => {
    setPaginationState((prev) => ({
      ...prev,
      [slug]: { ...prev[slug], pageNumber: newPage },
    }));
  };

  const handlePerRowsChange = (slug, newPageSize) => {
    setPaginationState((prev) => ({
      ...prev,
      [slug]: { ...prev[slug], pageSize: newPageSize, pageNumber: 1 },
    }));
  };

  return (
    <Page>
      {sections.map(({ slug, title }) => (
        <Grid item xs={12} key={slug}>
          <ProfileManager
            slug={slug}
            title={title}
            data={data[slug]}
            onPageChange={(newPage) => handlePageChange(slug, newPage)}
            onRowsPerPageChange={(newPageSize) => handlePerRowsChange(slug, newPageSize)}
            loading={loading}
          />
        </Grid>
      ))}
    </Page>
  );
};

export default UserProfile;
