import { toast } from "react-toastify";
import apiAuthLogin from "../../services/authServiceLogin";
import QuestionService from "../../services/questionServices";

export const handleAddQuestion = async (slug, isEditMode, currentQuestion, question, setQuestionData, setModalShow, setQuestion, setCurrentQuestion, setIsEditMode) => {
  try {
    let response;
    if (isEditMode && currentQuestion) {
      response = await QuestionService.edit({
        id: currentQuestion?._id,
        title: question,
      });
      if (response?.data?.status === 1) {
        toast.success(response?.data?.message);
        setQuestionData((prev) =>
          prev.map((item) =>
            item._id === currentQuestion._id ? { ...item, title: question } : item
          )
        );
      }
    } else {
      response = await QuestionService.add({
        title: question,
      });
      if (response?.data?.status === 1) {
        toast.success(response?.data?.message);
        setQuestionData((prev) => [
          { _id: response.data.newId, title: question, status: 1 },
          ...prev,
        ]);
      }
    }
  } catch (error) {
    console.error("Error adding or updating question:", error);
    toast.error("An error occurred while processing your request.");
  }
  setModalShow(false);
  setQuestion("");
  setCurrentQuestion(null);
  setIsEditMode(false);
};

export const handleEdit = (row, setCurrentQuestion, setQuestion, setIsEditMode, setModalShow) => {
  setCurrentQuestion(row);
  setQuestion(row.title);
  setIsEditMode(true);
  setModalShow(true);
};

export const handleDelete = async (statusId, setQuestionData, setShow) => {
  try {
    const response = await QuestionService.delete(statusId);
    if (response?.data?.status === 1) {
      toast.success(response?.data?.message);
      setQuestionData((prev) => prev.filter((item) => item._id !== statusId));
    }
  } catch (error) {
    console.error("Error deleting question:", error);
    toast.error("An error occurred while processing your request.");
  }
  setShow(false);
};

export const toggleChecked = async (currentStatus, statusId, setQuestionData, setShow) => {
  try {
    const newStatus = currentStatus === 1 ? 0 : 1;
    const response = await QuestionService.status({
      id: statusId,
      status: newStatus,
    });
    if (response?.data?.status === 1) {
      toast.success(response?.data?.message);
      setQuestionData((prev) =>
        prev.map((item) =>
          item._id === statusId ? { ...item, status: newStatus } : item
        )
      );
    }
  } catch (error) {
    console.log("Error updating question status:", error);
    toast.error("An error occurred while processing your request.");
  }
  setShow(false);
};
