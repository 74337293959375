import { EditOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Card, CardContent, CardHeader, DialogContent, FormControlLabel, FormGroup, IconButton, Switch, TextField, Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import * as Yup from "yup"; // Import Yup
import { Formik, Form, Field, ErrorMessage } from "formik"; // Import Formik
import { handleAddColor, handleDelete, handleEdit, toggleChecked } from "./Common";

function ProfileManager({ slug, title, data, onPageChange, onRowsPerPageChange, loading }) {
  const [modalShow, setModalShow] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [statusId, setStatusId] = useState("");
  const [warning, setWarning] = useState("");
  const [action, setAction] = useState("");
  const [show, setShow] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentColor, setCurrentColor] = useState(null);
  const [color, setColor] = useState("");
  const [colorData, setColorData] = useState(data?.items || []);

  useEffect(() => {
    setColorData(data?.items || []);
  }, [data]);

  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    onRowsPerPageChange(newPerPage);
    handlePageChange(page);
  };

  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
      grow: 0.5
    },
    {
      name: "Color Name",
      sortable: true,
      selector: (row) => (row?.title ? row?.title : "-"),
    },
    {
      name: "Status",
      cell: (row) => (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={row.status === 1}
                onClick={() => {
                  setShow(true);
                  setCurrentStatus(row.status);
                  setStatusId(row._id);
                  setAction("status");
                  setWarning("Are you sure you want to change the status?");
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
        </FormGroup>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Tooltip title="Edit" placement="left">
            <IconButton color="primary" onClick={() => handleEdit(row, setCurrentColor, setColor, setIsEditMode, setModalShow)}>
              <EditOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="right">
            <IconButton
              color="primary"
              onClick={() => {
                setShow(true);
                setAction("delete");
                setStatusId(row._id);
                setWarning("Are you sure you want to delete the color?");
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  // Yup validation schema
  const validationSchema = Yup.object({
    color: Yup.string()
      .required(`${title} is required`)
      .min(3, `${title} must be at least 3 characters`)
      .max(20, `${title} cannot be longer than 20 characters`),
  });

  return (
    <Card>
      <CardHeader titleTypographyProps={{ variant: "h4" }} title={title}></CardHeader>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        <Button size="large" type="button" variant="contained" sx={{ ml: 3, mr: 3 }} onClick={() => {
          setIsEditMode(false);
          setModalShow(true);
          setColor("");
        }}>
          Add
        </Button>
      </Box>
      <CardContent>
        <DataTable
          columns={columns}
          data={colorData || []}
          pagination
          paginationServer
          paginationTotalRows={data?.totalItems || 0}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 20, 50]}
          progressPending={loading}
        />
      </CardContent>

      <Dialog open={show} onClose={() => setShow(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>{warning}</DialogContent>
        <DialogActions>
          <Button onClick={() => setShow(false)}>Cancel</Button>
          <Button
            onClick={() => action === "delete"
              ? handleDelete(statusId, setColorData, setShow)
              : toggleChecked(currentStatus, statusId, setColorData, setShow)
            }
            variant="contained"
            color="secondary"
          >
            {action === "delete" ? "Delete" : "Status"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={modalShow} onClose={() => setModalShow(false)} maxWidth="sm" fullWidth>
        <DialogTitle>{isEditMode ? "Edit" : "Add"}</DialogTitle>

        <Formik
          initialValues={{ color }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleAddColor(
              slug,              // section slug (e.g., "eye-color")
              isEditMode,        // whether it's in edit mode or add mode
              currentColor,      // the color being edited, or null for new color
              values.color,      // new color title from the input field
              setColorData,      // update color data function
              setModalShow,      // close the modal after submit
              setColor,          // reset the color field
              setCurrentColor,   // reset the current color field
              setIsEditMode      // reset the edit mode state
            );
            setSubmitting(false);
            setModalShow(false); // Close the modal after the submit
          }}
          
        >
          {({ isSubmitting }) => (
            <Form>
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  minHeight: "100px",
                }}
              >
                {/* Input Field */}
                <Field
                  as={TextField}
                  autoFocus
                  margin="dense"
                  label={title}
                  name="color"
                  fullWidth
                />

                <Box
                  sx={{
                    minHeight: "20px",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  <ErrorMessage name="color" />
                </Box>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => setModalShow(false)}>Cancel</Button>
                <Button type="submit" variant="contained" disabled={isSubmitting}>
                  {isEditMode ? "Update" : "Add"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Card>
  );
}

export default ProfileManager;
