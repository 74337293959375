import { API_BASE } from '../utils/constants';
import AxiosInterceptor from "../utils/AxiosInterceptor";
const API_URL = API_BASE + "admin/";

const SubscriptionServiceApi = {
    getAll: (value) =>  AxiosInterceptor().post(API_BASE + "getAllSubscription", value),
    addSubscription: (value) => AxiosInterceptor().post(API_BASE + "addSubscription", value),
    updateSubscription: (value) => AxiosInterceptor().post(API_BASE + "updateSubscription", value),
    getOneSubscription: (value) => AxiosInterceptor().get(API_BASE + `getOneSubscription/${value.id}`),
    deleteSubscription: (value) => AxiosInterceptor().get(API_BASE + `deleteSubscription/${value.id}`),
  changeStatus: (value) => AxiosInterceptor().post(API_BASE + "changeStatusSubscription", value),
};

export default SubscriptionServiceApi;
