export function capitalize(str) {
    if (typeof str !== 'string' || !str) {
        return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isInThePast(date) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return date < today;
}

export const getSiteDomain = ({ site }) => {
    try {
        return ((new URL(site.url)).origin).replace(/^http(s?):\/\//, '').replace(/\/$/, '');
    } catch (e) {
        return site.url.replace(/^http(s?):\/\//, '').replace(/\/$/, '');
    }
};

export const formatNumber = (amount) => {
    if (amount === undefined || amount === null) {
        return '';
    }
    return amount.toLocaleString();
};


export function isRecentMember({ member }) {
    if (!member?.created_at) {
        return false;
    }

    const now = new Date();
    const created = new Date(member.created_at);
    const diff = now.getTime() - created.getTime();
    const diffHours = Math.round(diff / (1000 * 60 * 60));

    return diffHours < 24;
}

export const safeCallback = (callback, arg1, arg2, arg3) => {
    if (!callback) {
        return;
    }

    if (arg3 !== undefined) {
        callback(arg1, arg2, arg3);
        return;
    }
    if (arg2 !== undefined) {
        callback(arg1, arg2);
        return;
    }
    callback(arg1);
};

export const runningOnBrowser = typeof window !== "undefined";

export const isBot =
    (runningOnBrowser && !("onscroll" in window)) ||
    (typeof navigator !== "undefined" && /(gle|ing|ro)bot|crawl|spider/i.test(navigator.userAgent));

export const supportsIntersectionObserver = runningOnBrowser && "IntersectionObserver" in window;

export const supportsClassList = runningOnBrowser && "classList" in document.createElement("p");

export const supportsCreateImageBitmap = runningOnBrowser && "createImageBitmap" in window;

export const supportsFetch = runningOnBrowser && "fetch" in window;

export const isHiDpi = runningOnBrowser && window.devicePixelRatio > 1;


export function plainText(html) {
    return html.replace(/<\/?[^>]*>/g, '').replace(/&nbsp;/g, ' ').replace(/<br\s*[/]?>/gi, '\n').trim();
}


export const removeSpaces = (values) => {
    Object.keys(values).forEach((key) =>
        typeof values[key] === "string"
            ? (values[key] = values[key]?.trim()?.replace(/  +/g, " "))
            : values[key]
    );
    return values
}

export const getSrno = (currentPage, pageSize, index) => {
    return (currentPage == 1 ? 0 : (currentPage - 1) * pageSize) + index + 1
}

export const TrimText = (value, size = 40) => {
  return value != undefined && value.length > size
    ? value.slice(0, size) + "..."
    : value;
};