import { toast } from "react-toastify";
import apiAuthLogin from "../../services/authServiceLogin";

export const handleAddColor = async (slug,isEditMode, currentColor, color, setColorData, setModalShow, setColor, setCurrentColor, setIsEditMode) => {
  try {
    let response;
    if (isEditMode && currentColor) {
      response = await apiAuthLogin.updateProfileFeature({
        id: currentColor?._id,
        slug,
        title: color,
      });
      if (response?.data?.status === 1) {
        toast.success(response?.data?.message);
        setColorData((prev) =>
          prev.map((item) =>
            item._id === currentColor._id ? { ...item, title: color } : item
          )
        );
      }
    } else {
      response = await apiAuthLogin.addProfileFeature({
        slug,
        title: color,
      });
      if (response?.data?.status === 1) {
        toast.success(response?.data?.message);
        setColorData((prev) => [
          { _id: response.data.newId, title: color, status: 1 }, // New color on top
          ...prev, // Keep the rest of the data
        ]);
      }
    }
  } catch (error) {
    console.error("Error adding or updating color:", error);
    toast.error("An error occurred while processing your request.");
  }
  setModalShow(false);
  setColor("");
  setCurrentColor(null);
  setIsEditMode(false);
};


export const handleEdit = (row, setCurrentColor, setColor,setIsEditMode,setModalShow) => {
    setCurrentColor(row);
    setColor(row.title);
    setIsEditMode(true);
    setModalShow(true);
  };

  export const handleDelete = async (statusId,setColorData,setShow) => {
    try {
      const response = await apiAuthLogin.deleteProfileFeature(statusId);
      if (response?.data?.status === 1) {
        toast.success(response?.data?.message);
        // Update local state
        setColorData((prev) => prev.filter((item) => item._id !== statusId));
      }
    } catch (error) {
      console.error("Error deleting color:", error);
      toast.error("An error occurred while processing your request.");
    }
    setShow(false);
  };

  export const toggleChecked = async (currentStatus,statusId,setColorData,setShow) => {
    try {
      const newStatus = currentStatus === 1 ? 0 : 1;
      const response = await apiAuthLogin.statusProfileFeature({
        id: statusId,
        status: newStatus,
      });
      if (response?.data?.status === 1) {
        toast.success(response?.data?.message);
        // Update local state
        setColorData((prev) =>
          prev.map((item) =>
            item._id === statusId ? { ...item, status: newStatus } : item
          )
        );
      }
    } catch (error) {
      console.log("Error status color:", error);
      toast.error("An error occurred while processing your request.");
    }
    setShow(false);
  };
  