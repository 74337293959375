import axios from "axios";
import { API_BASE } from "./constants";

const authHeader = () => {
    const token = localStorage.getItem("token");
    if (token) {
        return { Authorization: "Bearer " + token };
    }
    return {};
};

export default (history = null) => {
    const axiosInstance = axios.create({ baseURL: API_BASE, headers: authHeader() });
    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && (error.response.status === 403 || error.response.status === 401)) {
                // do something
                const { message } = error.response.data;
                if (message) {
                    console.log(message)
                } else {
                    // tostE(ErrorMessage.logoutMsg)
                }
                setTimeout(() => {
                    localStorage.clear();
                    if (history) {
                        history.push("/login");
                    } else {
                        window.location = "/login";
                    }
                }, 1000);
            }
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};
