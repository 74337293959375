import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Page from "../../components/Page";
import ProfileManager from "./QuestionManagement";
import QuestionService from "../../services/questionServices";

const sections = [
  { slug: "questions", title: "Questions" },
];

const QuestionList = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [paginationState, setPaginationState] = useState({
    questions: { pageNumber: 1, pageSize: 5, totalItems: 0 }
  });

  const fetchProfileData = async (pageNumber = 1, pageSize = 5) => {
    try {
      setLoading(true);
      const value = { search: "", pageSize, pageNumber, slug: "questions" };
      const response = await QuestionService.getAll(value);
      const result = response?.data?.data;

      setData({
        questions: {
          items: result?.questions || [],
          totalItems: result?.totalRecords || 0,
        },
      });

      setPaginationState({
        questions: {
          pageNumber,
          pageSize,
          totalItems: result?.totalRecords || 0,
        },
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfileData(paginationState.questions.pageNumber, paginationState.questions.pageSize);
  }, [paginationState.questions.pageNumber, paginationState.questions.pageSize]);

  const handlePageChange = (newPage) => {
    setPaginationState((prev) => ({
      ...prev,
      questions: { ...prev.questions, pageNumber: newPage },
    }));
  };

  const handlePerRowsChange = (newPageSize) => {
    setPaginationState((prev) => ({
      ...prev,
      questions: { ...prev.questions, pageSize: newPageSize, pageNumber: 1 },
    }));
  };

  return (
    <Page>
      <Grid item xs={12}>
        <ProfileManager
          slug="questions"
          title="Questions"
          data={data.questions}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handlePerRowsChange}
          loading={loading}
        />
      </Grid>
    </Page>
  );
};

export default QuestionList;
