import { EditOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, Card, CardContent, CardHeader, FormControlLabel, FormGroup, IconButton, Switch, Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
// import TableFilter from "../../components/shared/TableFilter";

const staticData = [
  { id: 1, fullName: "John Doe", email: "john.doe@example.com", phoneNumber: "123-456-7890", createdAt: "2024-01-01T10:00:00Z", updatedAt: "2024-01-02T12:00:00Z", status: 1 },
  { id: 2, fullName: "Jane Smith", email: "jane.smith@example.com", phoneNumber: "987-654-3210", createdAt: "2024-02-01T11:00:00Z", updatedAt: "2024-02-02T13:00:00Z", status: 0 },
];

function ManageAndAccessUserAccounts() {
  const [data, setData] = useState(staticData);
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [statusId, setStatusId] = useState("");
  const [viewId, setViewId] = useState("");
  const [editId, setEditId] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [filteredData, setFilteredData] = useState("");
  const [warning, setWarning] = useState("");
  const [action, setAction] = useState("");
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState([]);

  const handlePageChange = (page) => {
    // Pagination logic if needed
  };

  const toggleChecked = (id, statusChange) => {
    // Handle status toggle (e.g., update static data here if necessary)
  };

  const handleDelete = (id) => {
    // Handle deletion (e.g., remove from static data here if necessary)
  };

  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
      grow: 0.5
    },
    {
      name: "User Id",
      selector: (row) => row?.id
    },
    {
      name: "Name",
      sortable: true,
      selector: (row) => (row?.fullName ? row?.fullName : "-"),
    },
    {
      name: "Email",
      sortable: true,
      selector: (row) => (row?.email ? row?.email : "-"),
    },
    {
      name: "Phone Number",
      sortable: true,
      selector: (row) => row.phoneNumber ? row.phoneNumber : "-",
    },
    {
      name: "Created At",
      sortable: true,
      selector: (row) => (row.createdAt ? new Date(row.createdAt).toLocaleString() : "-"),
    },
    {
      name: "Modified At",
      selector: (row) => (row.updatedAt ? new Date(row.updatedAt).toLocaleString() : "-"),
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={row.status === 1}
                  onClick={() => {
                    setShow(true);
                    setCurrentStatus(row.status);
                    setStatusId(row.id);
                    setAction("status");
                    setWarning("Are you sure want to change the status? ");
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </FormGroup>
        </>
      ),
    },
    {
      name: <div style={{ textAlign: "center", marginLeft: "40px" }}>Action</div>,
      cell: (row) => (
        <>
          <Tooltip title="Edit" placement="left">
            <IconButton
              color="primary"
              onClick={() => {
                setEditId(row.id);
                navigate(`/manage-and-access-user-accounts/edit/${row.id}`);
              }}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>

          <Tooltip title="View" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setModalShow(true);
                setViewId(row.id);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="right">
            <IconButton
              color="primary"
              onClick={() => {
                setShow(true);
                setAction("delete");
                setStatusId(row.id);
                setWarning("Are you sure want to delete the user?");
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Card>
      <CardHeader titleTypographyProps={{ variant: "h4" }} title="Manage And Access User Accounts"></CardHeader>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        {/* <TableFilter
          value={status}
          setValue={setStatus}
          filterName="Status"
        /> */}
        {/* <SearchBtn2 search={searchItem} setSearch={setSearchItem} /> */}
        <Button size="large" type="button" variant="contained" sx={{ ml: 3, mr: 3 }} onClick={() => navigate("/manage-and-access-user-accounts/add")}>
          Add
        </Button>
      </Box>

      <CardContent>
        <DataTable
          columns={columns}
          data={filteredData ? filteredData : data}
          pagination
          paginationServer
          paginationTotalRows={data.length}
          onChangePage={handlePageChange}
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 20, 50]}
          onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
          }}
        />
      </CardContent>

      <Dialog open={show} keepMounted onClose={() => setShow(false)} aria-describedby="alert-dialog-slide-description">
        <DialogTitle as="h2">{warning}</DialogTitle>

        <DialogActions>
          <Button
            variant="outlined"
            style={{ backgroundColor: "White" }}
            onClick={() => {
              if (action === "status") {
                toggleChecked(statusId, currentStatus);
              } else if (action === "delete") {
                handleDelete(statusId);
              }
              setShow(false);
            }}
          >
            Yes
          </Button>
          <Button variant="outlined" style={{ backgroundColor: "White" }} onClick={() => setShow(false)}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default ManageAndAccessUserAccounts;