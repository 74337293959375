import { API_BASE } from '../utils/constants';
import AxiosInterceptor from "../utils/AxiosInterceptor";
const API_URL = API_BASE + "admin/";

const GiftServiceApi = {
    getAll: (value) =>  AxiosInterceptor().post(API_BASE + "getAllGift", value),
    addGift: (value) => AxiosInterceptor().post(API_BASE + "addGift", value),
    updateGift: (value) => AxiosInterceptor().post(API_BASE + "updateGift", value),
    getOneGift: (value) => AxiosInterceptor().get(API_BASE + `getOneGift/${value.id}`),
    deleteGift: (value) => AxiosInterceptor().get(API_BASE + `deleteGift/${value.id}`),
  changeStatus: (value) => AxiosInterceptor().post(API_BASE + "changeStatusGift", value),
};

export default GiftServiceApi;
