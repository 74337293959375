import axios from "axios";
import { API_BASE } from "../utils/constants";

const API_URL = API_BASE;

const apiAuth = {
  login: (value) => axios.post(API_URL + "login", value),

  forgot: (value) => axios.post(API_URL + "forgotPassword", value),

  resetPassword: (value) => axios.post(API_URL + 'resetPassword', value),

  // verifyOtp: (value) => axios.post(API_URL + 'verify-otp', value),

  // resendOtp: (value) => axios.post(API_URL + 'resend-otp', value),

  // resetPassword: (value) => axios.post(API_URL + "updatePassword", value),
};

export default apiAuth;

